<template>
  <div class="app flex-row align-items-center guest">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col xs="12" md="8" lg="6">
          <b-card-group>
            <b-card no-body class="p-3">
              <b-card-body>
                <form @submit.prevent="validateBeforeSubmit">
                  <img class="mx-auto d-block mb-4 navbar-brand-full" src="img/brand/invoice-logo-2019.png" height="95"
                    alt="RTNEST Inc. Logo">
                  <h1>Log In</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <flash-message autoHide variant="success"></flash-message>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="text" class="form-control" name="email" v-model="email"
                      v-validate="'required|email'" placeholder="Email" autocomplete="username email" />
                  </b-input-group>
                  <i v-show="errors.has('email')" class="fa fa-exclamation-triangle mb-3"></i> <span
                    v-show="errors.has('email')" class="mb-3 help is-danger">{{ errors.first('email') }}</span>

                  <b-input-group class="mb-2">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="password" class="form-control" name="password" v-validate="'required'"
                      v-model="password" placeholder="Password" autocomplete="current-password" />
                  </b-input-group>
                  <i v-show="errors.has('password')" class="fa fa-exclamation-triangle mb-3"></i> <span
                    v-show="errors.has('password')" class="mb-3 help is-danger">{{ errors.first('password') }}</span>

                  <b-input-group class="mb-2">
                    <b-input-group-prepend style="z-index: 99;">
                      <b-input-group-text><i class="icon-settings"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <select class="select-account form-control" name="account" v-validate="'required'"
                      v-model="account">
                      <option value="" disabled selected>Account Type (Select Your Role)</option>
                      <option for v-for="option in accounts" v-bind:value="option.url">{{ option.accountType }}</option>
                    </select>
                    <i class="dropdown-icon fa fa-chevron-down position-absolute"></i>
                  </b-input-group>
                  <i v-show="errors.has('account')" class="fa fa-exclamation-triangle"></i> <span
                    v-show="errors.has('account')" class="mb-3 help is-danger">The account type is required.</span>


                  <input v-if="url == 'invoice.rtnest.ca' || url == 'www.invoice.rtnest.ca'" type="hidden" name="robot"
                    v-model="robot" v-validate="'required'" />
                  <span v-show="signInError" class="help is-danger">
                    <h5 class="mt-2"><i v-show="signInError" class="fa fa-exclamation-triangle"></i> Please make sure
                      the followings are correct</h5>
                    <ol style="list-style-type: upper-greek;">
                      <li>You are using "Google Chrome" or compatible browser.</li>
                      <li>Your user name is the email that your company was registered with.</li>
                      <li>Your password is correct.</li>
                      <li>The role you choose is correct (e.g. If you want to submit field tickets/invoices, you are a
                        vendor!).</li>
                    </ol>
                    <p>Otherwise, contact admin for support at <a href=" support@rtnest.ca">support@rtnest.ca</a></p>
                  </span>

                  <span v-show="inActiveError" class="help is-danger">
                    <h5 class="mt-2"><i v-show="inActiveError" class="fa fa-exclamation-triangle"></i> This Account is
                      inactive.</h5>
                    <p>Please contact your Admin or RTNest support at <a href=" support@rtnest.ca">support@rtnest.ca</a>
                    </p>
                  </span>

                  <span v-show="lockedError" class="help is-danger">
                    <h5 class="mt-2"><i v-show="lockedError" class="fa fa-exclamation-triangle"></i> The Account is
                      temporarily Locked. <b-button :to="{ name: 'Forgot' }" variant="link" class="px-0"> Reset Password
                      </b-button>
                    </h5>
                  </span>

                  <b-input-group class="cap">
                    <vue-recaptcha ref="reCaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" class="mt-2"
                      sitekey="6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6">
                    </vue-recaptcha>
                  </b-input-group>
                  <i v-show="errors.has('robot')" class="fa fa-exclamation-triangle mt-2 mb-2"></i> <span
                    v-show="errors.has('robot')" class="mb-3 help is-danger">The reCaptcha is required.</span>


                  <b-row class="mt-3">
                    <b-col cols="12" class="col-md-5">
                      <b-button variant="primary" type="submit" class="w-100 px-5">Login</b-button>
                    </b-col>
                    <b-col cols="12" class="text-md-right col-md-7">
                      <b-button :to="{ name: 'Forgot' }" variant="link" class="px-0">Forgot password?</b-button>
                    </b-col>
                  </b-row>
                  <!-- <b-row class="mt-3">
                    <hr>
                    Support # 1 780 708 2495
                    <br>
                    Support Email: dev@rtnest.ca
                  </b-row> -->
                </form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Vue from 'vue'
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'Login',
  components: {
    vSelect,
    VueRecaptcha
  },
  data: function () {
    return {
      signInError: false,
      inActiveError: false,
      lockedError: false,
      email: '',
      password: '',
      account: '',
      robot: '',
      url: '',
      accounts: [
        {
          url: "accountant",
          accountType: "Accountant"
        },
        {
          url: "admin",
          accountType: "Admin"
        },
        {
          url: "manager",
          accountType: "Manager"
        },
        {
          url: "wts",
          accountType: "Project Manager"
        },
        {
          url: "sys_admin",
          accountType: "System Admin"
        },
        {
          url: "vendor",
          accountType: "Vendor"
        },
        {
          url: "wss",
          accountType: "Well Site Supervisor"
        },
      ]
    }
  },
  mounted() {
    this.url = window.location.hostname;
  },
  beforeCreate() {
    //do something before mounting vue instance
    if (!this.$session.has('query')) {
      this.$session.set('query', '');
    }
    if (!this.$session.has('orderBy')) {
      this.$session.set('orderBy', { ascending: true, column: false });
    }
    if (!this.$session.has('page')) {
      this.$session.set('page', 1);
    }
  },
  methods: {
    onCaptchaExpired: function () {
      this.robot = '';
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.robot = 'true';
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            email: this.email,
            password: this.password
          })
          let url = this.account;
          console.log('url: ',url)
          let redirect = "";
          if (url === 'accountant') {
            url = "manager";
            redirect = "accountant";
          }
          if (url === 'wts') {
            url = "wts";
            redirect = "pm";
          } else {
            redirect = this.account;
          }
          this.$axios.post("/" + url + '/signin', data)
            .then( response => {
              if (response.status != 200) {
                this.$toasted.show('Error.', { type: 'error', duration: '3000' })
                this.error = true;

              }
              if (response.status === 200) {
                this.$session.start()
                this.$session.set('jwt', response.data.session)
                this.$session.set('user', redirect);
                this.flash({ message: 'Login Successful', variant: 'success' });
                console.log(redirect)
                this.$router.push("/" + redirect).catch((error) => {
                  console.log(error);
                })
              }
            })
            .catch(error => {
              console.log(error.response.data);
              if (error.response.data.details == "ACCOUNT_INACTIVE") {
                this.lockedError = false;
                this.inActiveError = true;
                this.signInError = false;
              } else if (error.response.data.details == "ACCOUNT_LOCKED") {
                this.lockedError = true;
                this.inActiveError = false;
                this.signInError = false;
              } else {
                this.lockedError = false
                this.inActiveError = false;
                this.signInError = true;
              }
              this.robot = '';
              this.$refs.reCaptcha.reset();
            })
          return;
        }
      });
    },
  }
}
</script>

<style lang="scss">
.select-account {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 10px;
}

.fa-exclamation-triangle {
  margin-right: 7px;
}

.dropdown-icon {
  right: 15px;
  top: 10px;
  z-index: 99;
}
</style>
